import React from "react"

export default function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="linkedin">
          <path
            id="Path_13094"
            data-name="Path 13094"
            d="M0-66.148H20v20H0Z"
            transform="translate(0 66.148)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_43448"
        data-name="Group 43448"
        transform="translate(0 66.148)"
      >
        <g
          id="Group_27638"
          data-name="Group 27638"
          transform="translate(0 -66.148)"
          clipPath="url(#linkedin)"
        >
          <path
            id="Path_13092"
            data-name="Path 13092"
            d="M.112-60.1H4.388v13.952H.112Zm0,13.952M4.529-63.98a2.145,2.145,0,0,1-2.307,2.168A2.121,2.121,0,0,1,0-63.98a2.136,2.136,0,0,1,2.278-2.168A2.129,2.129,0,0,1,4.529-63.98"
            transform="translate(0 66.148)"
            fill="#fff"
          />
          <g
            id="Group_27637"
            data-name="Group 27637"
            transform="translate(6.835 5.734)"
          >
            <path
              id="Path_13093"
              data-name="Path 13093"
              d="M-.265-10.994c0-1.74-.056-3.195-.112-4.451H3.336l.2,1.94h.084a4.925,4.925,0,0,1,4.248-2.254c2.813,0,4.923,1.912,4.923,6.02v8.246H8.512V-9.225c0-1.8-.619-3.024-2.166-3.024a2.353,2.353,0,0,0-2.194,1.626,3.169,3.169,0,0,0-.141,1.085v8.046H-.265Z"
              transform="translate(0.377 15.759)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
