// Libraries.
import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

// Shared Components.
import { Container } from "../../shared/Container"

// Assets.
import comillasClose from "./assets/comillas-close.svg"
import comillasOpen from "./assets/comillas-open.svg"
import logoToyota from "./assets/logo-toyota.png"
import logoNomi from "./assets/logo-nomi.png"
import logoAltesa from "./assets/logo-altesa.png"

// Styles.
import {
  WrapperClientSection,
  DescriptionClient,
  DataClient,
  WrapperLogo,
  WrapperCarousel,
  TextWhite,
  WrapperDescription,
} from "./styles"

const Clients = () => {
  const dataCarousel = [
    {
      id: 1,
      recommendation:
        "I greatly appreciate all of the support you have provided Toyota on our SAP project. Your partnership and quick response to our needs and questions have been extremely valuable to our implementation work for our Mexico entities.",
      greetings: "Warmest regards,",
      clientName: "Susan",
      completeClientName: "Susan Kawamoto",
      positionClient: "Product Owner at Toyota Motor North America",
      logo: logoToyota,
    },
    {
      id: 2,
      width: "580px",
      recommendation:
        "I’ve known Diego for several years now. He’s always been professional and an excellent creative partner for Altesa.",
      greetings: "",
      clientName: "",
      completeClientName: "Carlos Rivas",
      positionClient: "President at Altesa",
      logo: logoAltesa,
    },
    {
      id: 3,
      width: "580px",
      recommendation:
        "Today is my last day.  It’s been a great pleasure working with you Diego. You were wonderful to work with. Thank you so much for making my job easier.",
      greetings: "Best,",
      clientName: "Lily",
      completeClientName: "Lilian Dendrinos",
      positionClient: "ERP RTR Core Team at Toyota Motor North America",
      logo: logoToyota,
    },
    {
      id: 4,
      recommendation:
        "His work, creativity, and strategic vision allow us to compete in the big leagues against global companies. Bigger teams with much higher budgets have lost their business to our nimble and well-motivated development team commanded by Diego.",
      greetings: "",
      clientName: "",
      completeClientName: "Eduardo Sánchez",
      positionClient: "CEO at NOMI",
      logo: logoNomi,
    },
  ]
  const renderClients = dataCarousel.map(client => {
    return (
      <Container>
        <DescriptionClient>
          <WrapperDescription width={client.width}>
            {client.recommendation}
          </WrapperDescription>
          <TextWhite color={client.greetings}>
            {client.greetings ? client.greetings : "a"}
          </TextWhite>
          <TextWhite color={client.clientName}>
            {client.clientName ? client.clientName : "a"}
          </TextWhite>
        </DescriptionClient>
        <DataClient>
          <p>{client.completeClientName}</p>
          <p>{client.positionClient}</p>
        </DataClient>
        <WrapperLogo src={client.logo} alt="logos toyota" />
      </Container>
    )
  })
  return (
    <WrapperCarousel>
      <hr />
      <section>
        <Container>
          <WrapperClientSection>
            <Carousel
              preventMovementUntilSwipeScrollTolerance
              showStatus={false}
              swipeScrollTolerance={100}
              showArrows={false}
            >
              {renderClients}
            </Carousel>
            <img
              className="comillas-open"
              src={comillasOpen}
              alt="comillas abren"
            />
            <img
              className="comillas-close"
              src={comillasClose}
              alt="comillas cierran"
            />
          </WrapperClientSection>
        </Container>
      </section>
      <hr />
    </WrapperCarousel>
  )
}

export default Clients
