// Libraries.
import React, { useEffect, useState } from "react"

// Shared Components.
import { Container } from "../../shared/Container"

// Styles.
import {
  SectionHero,
  WrapperHero,
  Hamburger,
  HeroImg,
  HeroImgMobile,
} from "./styles"

// Assets.
import hamburger from "./assets/hamburger.svg"
import diego from "./assets/diego-hero.png"
import diegoMobile from "./assets/phone-hero.png"
import useWindowSize from "@src/hooks/useWindowSize"

const Hero = ({ setData }) => {
  const [heightHero, setHeightHero] = useState(0)
  const windowSize = useWindowSize()
  const heroSize = 0.87 * windowSize?.height
  const heroSizePhone = 0.63 * heightHero
  const descriptionSize = 0.31 * windowSize?.width
  const descriptionPhoneSize = 0.49 * windowSize?.width
  const tabletSize = windowSize?.width < 900
  const phoneSize = windowSize?.width < 643

  useEffect(() => {
    if (windowSize.height && heightHero === 0) {
      setHeightHero(windowSize.height || 0)
    }
  }, [windowSize.height])

  return (
    <>
      <SectionHero height={heightHero}>
        {/* {windowSize?.width}x{windowSize?.height} */}
        <Container style={{ height: "100%" }}>
          <Hamburger onClick={() => setData({ sidebar: true })}>
            <img src={hamburger} alt="hamburger bar" />
          </Hamburger>
          <WrapperHero>
            <div>
              <h1>Hello,</h1>
              <p
                style={{
                  maxWidth: `${
                    phoneSize ? descriptionPhoneSize : descriptionSize
                  }px`,
                }}
              >
                I aim to be a creative contribution to your team and a
                profitable asset to your business. My{" "}
                <span style={{ whiteSpace: "nowrap" }}>multi-faceted</span>{" "}
                designer experience can bring{" "}
                <span style={{ whiteSpace: "nowrap" }}>high-level </span>
                skills in different areas such as Branding, Digital Design,
                Marketing, and Strategy.
              </p>
            </div>
          </WrapperHero>

          {windowSize?.height && (
            <>
              {phoneSize ? (
                <HeroImg
                  src={diegoMobile}
                  alt="diego hero"
                  height={heroSizePhone}
                />
              ) : (
                <HeroImg
                  src={diego}
                  alt="diego hero"
                  height={tabletSize ? heroSizePhone : heroSize}
                />
              )}
            </>
          )}
        </Container>
      </SectionHero>
      {/* <img src={diego} height={800} alt="" /> */}
    </>
  )
}

export default Hero
