// Libraries.
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const BriefcaseData = [
  {
    id: 1,
    selected: true,
    projectType: "digitalDesign",
    name: "NOMI",
    year: 2021,
    area: "DIGITAL DESIGN",
    client: "NOMI",
    project: "WEBSITE",
    modal: "Nomi0",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/02_DIGITAL_NOMI-website.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 2,
    selected: true,
    projectType: "branding",
    name: "CITYMEDIA",
    year: 2018,
    area: "BRANDING",
    client: "CITYMEDIA",
    project: "BRAND DEVELOPMENT",
    modal: "CityMedia0",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/02_BRANDING_CITYMEDIA/01_BRANDING_CITYMEDIA.jpg"
        alt="city media 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 3,
    selected: true,
    projectType: "strategy",
    name: "ALTESA",
    year: 2020,
    area: "STRATEGY",
    client: "ALTESA",
    project: "Great Place To Work® Certify",
    modal: "Altesa0",
    mainImage: (
      <StaticImage
        src="./project-images/04 STRATEGY/03_STRATEGY_ALTESA/01_STRATEGY_ALTESA.jpg"
        alt="altesa 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 4,
    selected: true,
    projectType: "branding",
    name: "TOPDOC",
    year: 2017,
    area: "BRANDING",
    client: "TOPDOC",
    project: "BRAND DEVELOPMENT",
    modal: "TopDoc0",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/04_BRANDING_TOPDOC/04_BRANDING_TOPDOC.jpg"
        alt="topdoc 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 5,
    selected: true,
    projectType: "digitalDesign",
    name: "NOMI",
    year: 2021,
    area: "DIGITAL DESIGN",
    client: "NOMI",
    project: "MY COMPANY",
    modal: "Nomi1",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN//05_DIGITAL_NOMI-MYCOMPANY/01_DIGITAL_NOMI-MYCOMPANY.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 6,
    selected: true,
    projectType: "digitalDesign",
    name: "NOMI",
    year: 2021,
    area: "DIGITAL DESIGN",
    client: "TOYOTA",
    project: "DICOMA",
    modal: "Nomi2",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/06_DIGITAL_TOYOTA-DICOMA/01_DIGITAL_TOYOTA-DICOMA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 7,
    selected: true,
    projectType: "digitalDesign",
    name: "NOMI",
    year: 2021,
    area: "DIGITAL DESIGN",
    client: "NOMI",
    project: "INTEGRITY",
    modal: "Nomi3",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/07_DIGITAL_NOMI-INTEGRITY/01_DIGITAL_NOMI-INTEGRITY.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 8,
    selected: true,
    projectType: "branding",
    name: "ALTESA",
    year: 2019,
    area: "BRANDING",
    client: "ALTESA",
    project: "PRODUCT PACKAGING",
    modal: "Altesa2",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/08_BRANDING_ALTESA/01_BRANDING_ALTESA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 9,
    selected: true,
    projectType: "digitalDesign",
    name: "CITYMEDIA",
    year: 2018,
    area: "DIGITAL DESIGN",
    client: "CITYMEDIA",
    project: "WEBSITE",
    modal: "Citymedia",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/09_DIGITAL_CITYMEDIA-WEBSITE/01_DIGITAL_CITYMEDIA-website.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 10,
    selected: true,
    projectType: "strategy",
    name: "IKIGAI",
    year: 2018,
    area: "STRATEGY",
    client: "KATANA",
    project: "BRAND IKIGAI",
    modal: "Ikigai",
    mainImage: (
      <StaticImage
        src="./project-images/04 STRATEGY/10_STRATEGY_IKIGAI/05_STRATEGY_IKIGAI.png"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 11,
    selected: true,
    projectType: "strategy",
    name: "GRUPO PPA",
    year: 2018,
    area: "STRATEGY",
    client: "GRUPO PPA",
    project: "INTERNAL COMMUNICATION",
    modal: "Ppa0",
    mainImage: (
      <StaticImage
        src="./project-images/04 STRATEGY/11_STRATEGY_GRUPOPPA/01_STRATEGY_GRUPOPPA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 12,
    selected: true,
    projectType: "digitalDesign",
    name: "NOMI",
    year: 2022,
    area: "DIGITAL DESIGN",
    client: "NOMI",
    project: "FISCAL VAULT",
    modal: "Nomi",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/12_DIGITAL_NOMI-FISCALVAULT/01_DIGITAL_NOMI-FISCALVAULT.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 13,
    selected: true,
    projectType: "branding",
    name: "GRUPO PPA",
    year: 2018,
    area: "BRANDING",
    client: "GRUPO PPA",
    project: "BRAND DEVELOPMENT",
    modal: "Ppa1",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/13_BRANDING_GRUPOPPA/01_BRANDING_GRUPOPPA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 14,
    selected: true,
    projectType: "marketingSales",
    name: "VIRTUAL PITCH",
    year: 2020,
    area: "MARKETING & SALES",
    client: "KATANA",
    project: " VIRTUAL PITCH",
    modal: "Pich",
    mainImage: (
      <StaticImage
        src="./project-images/03 MKT/14_MKT_VIRTUAL-PITCH/01_MKT_VIRTUAL-PITCH.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 15,
    selected: true,
    projectType: "digitalDesign",
    name: "TOPDOC",
    year: 2017,
    area: "DIGITAL DESIGN",
    client: "TOPDOC",
    project: "MOBILE APP",
    modal: "TodDoc2",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/15_DIGITAL_TOPDOC/01_DIGITAL_TOPDOC.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 16,
    selected: true,
    projectType: "digitalDesign",
    name: "CITY MEDIA",
    year: 2018,
    area: "DIGITAL DESIGN",
    client: "CITYMEDIA",
    project: "WEB APP",
    modal: "Media",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/16_DIGITAL_CITYMEDIA-COTIZADOR/01_DIGITAL_CITYMEDIA-COTIZADOR.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 17,
    selected: true,
    projectType: "branding",
    name: "GRUPO PPA",
    year: 2015,
    area: "BRANDING",
    client: "GRUPO PPA",
    project: "OFFICE REMODELING",
    modal: "Ppa2",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/17_BRANDING_GRUPOPPA-OFFICE/01_BRANDING_GRUPOPPA-OFFICE.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 18,
    selected: true,
    projectType: "digitalDesign",
    name: "GRUPO PPA",
    year: 2018,
    area: "DIGITAL DESIGN",
    client: "GRUPO PPA",
    project: "WEBSITE",
    modal: "Ppa3",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/18_DIGITAL_GRUPOPPA-WEBSITE/01_DIGITAL_GRUPOPPA-WEBSITE.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 19,
    selected: true,
    projectType: "marketingSales",
    name: "TOPDOC",
    year: 2017,
    area: "MARKETING & SALES",
    client: "TOPDOC",
    project: "INBOUND STRATEGY",
    modal: "TocDoc",
    mainImage: (
      <StaticImage
        src="./project-images/03 MKT/19_MKT_TOPDOC/01_MKT_TOPDOC(thumbnail).jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 20,
    selected: true,
    projectType: "branding",
    name: "WMG",
    year: 2014,
    area: "BRANDING",
    client: "WMG",
    project: "VIDEO PRODUCTION",
    modal: "Wmg",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/20_BRANDING_WMG/01_BRANDING_WMG(thumbnail).jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 21,
    selected: true,
    projectType: "branding",
    name: "VENSI",
    year: 2013,
    area: "BRANDING",
    client: "VENSI",
    project: "OFFICE VINYL",
    modal: "Vensi",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/21_BRANDING_VENSI/01_BRANDING_VENSI.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 22,
    selected: true,
    projectType: "branding",
    name: "APUNTES",
    year: 2017,
    area: "DIGITAL BRANDING",
    client: "SARA ÁLVARES",
    project: "BOOK ILLUSTRATION",
    modal: "Apuntes",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/22_BRANDING_APUNTES/01_BRANDING_APUNTES.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 23,
    selected: true,
    projectType: "digitalDesign",
    name: "ALTESA",
    year: 2019,
    area: "DIGITAL DESIGN",
    client: "ALTESA",
    project: "WEBSITE",
    modal: "Altesa",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/23_DIGITAL_ALTESA/01_DIGITAL_ALTESA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 24,
    selected: true,
    projectType: "branding",
    name: "CCTN",
    year: 2016,
    area: "BRANDING",
    client: "CCTN",
    project: "REBRANDING AND REMODELING",
    modal: "Ccnt",
    mainImage: (
      <StaticImage
        src="./project-images/01 BRANDING/24_BRANDING_CCTN/01_BRANDING_CCTN.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 25,
    selected: true,
    projectType: "strategy",
    name: "LA RIOJA",
    year: 2017,
    area: "STRATEGY",
    client: "LA RIOJA",
    project: "INTERNAL COMMUNICATION",
    modal: "Rioja",
    mainImage: (
      <StaticImage
        src="./project-images/04 STRATEGY/25_STRATEGY_LARIOJA/01_STRATEGY_LARIOJA.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 26,
    selected: true,
    projectType: "digitalDesign",
    name: "GSCP",
    year: 2016,
    area: "DIGITAL DESIGN",
    client: "GSCP",
    project: "WEB APP",
    modal: "Gscp",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/26_DIGITAL_GSCP/01_DIGITAL_GSCP.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
  {
    id: 27,
    selected: true,
    projectType: "digitalDesign",
    name: "GRUPO PPA",
    year: 2019,
    area: "DIGITAL DESIGN",
    client: "GRUPO PPA",
    project: "WEB APP",
    modal: "Ppa",
    mainImage: (
      <StaticImage
        src="./project-images/02 DIGITAL DESIGN/27_DIGITAL_GRUPOPPA-PPAPP/01_DIGITAL_GRUPOPPA-PPAPP.jpg"
        alt="nomi 1"
        width={586}
        height={394}
        style={{ width: "100%" }}
      />
    ),
  },
]
