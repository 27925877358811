// https://www.npmjs.com/package/react-simple-star-rating
import React, { useMemo, useReducer, useCallback, Fragment } from "react"

function StarIcon({
  size = 25,
  strokeColor = "none",
  storkeWidth = 0,
  className = "star-svg",
  style = { display: "inline" },
}) {
  return (
    <svg
      fill="currentColor"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={className}
      style={{ ...style }}
    >
      <path
        fill="currentColor"
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeWidth={storkeWidth}
        d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
      />
    </svg>
  )
}

function reducer(state, action) {
  switch (action.type) {
    case "PointerMove":
      return {
        ...state,
        hoverValue: action.payload,
      }

    case "PointerLeave":
      return {
        defaultValue: state.defaultValue,
        hoverValue: null,
      }

    case "MouseClick":
      return {
        ...state,
        defaultValue: action.payload,
      }

    default:
      return state
  }
}

function Rating({
  initialValue = 0,
  ratingValue = 0,
  iconsCount = 5,
  size = 40,
  readonly = false,
  fillColor = "#ffbc0b",
  fillColorArray = [],
  emptyColor = "#cccccc",
  fullIcon = null,
  emptyIcon = null,
  customIcons = [],
  rtl = false,
  allowHalfIcon = false,
  allowHover = true,
  transition = false,
  className = "react-simple-star-rating",
  style,
  fullClassName = "filled-icons",
  marginRight = 0,
  emptyClassName = "empty-icons",
  fullStyle,
  emptyStyle,
}) {
  const [{ defaultValue, hoverValue }, dispatch] = useReducer(reducer, {
    defaultValue: ratingValue,
    hoverValue: null,
  })

  // re-render when ratingValue changes
  React.useEffect(
    () => dispatch({ type: "MouseClick", payload: ratingValue }),
    [ratingValue]
  )

  // if there is a local rating value, convert it to precentage
  const localRating = useMemo(
    () => Math.round((initialValue / iconsCount) * 100),
    [initialValue, iconsCount]
  )

  /**
   * convert rating value to percentage value
   * @returns `hover value` | `rating value` | `local rating`
   */
  const valuePercentage = useMemo(
    () =>
      (allowHover && hoverValue && hoverValue) ||
      (defaultValue && defaultValue) ||
      localRating,
    [allowHover, hoverValue, defaultValue, localRating]
  )

  // handle total icons
  const totalIcons = useMemo(
    () => (allowHalfIcon ? iconsCount * 2 : iconsCount),
    [allowHalfIcon, iconsCount]
  )

  // convert value to index
  const valueIndex = useCallback(
    value => {
      let index = 1
      if (value) {
        index = Math.round((value / 100) * totalIcons) + 1
      }

      return Math.round(index - 1)
    },
    [totalIcons]
  )

  return (
    <span
      style={{
        display: "inline-block",
        direction: `${rtl ? "rtl" : "ltr"}`,
        touchAction: "none",
      }}
    >
      <span
        className={className}
        style={{
          position: "relative",
          display: "inline-block",
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: readonly ? "" : "pointer",
          verticalAlign: "middle",
          userSelect: "none",
          ...style,
        }}
        aria-hidden="true"
      >
        <span
          className={emptyClassName}
          style={{
            display: "inline-block",
            color: emptyColor,
            ...emptyStyle,
          }}
        >
          {[...Array(iconsCount)].map((_, index) => (
            <Fragment key={index}>
              {customIcons[index]?.icon || emptyIcon || (
                <StarIcon key={index} size={size} style={{ marginRight }} />
              )}
            </Fragment>
          ))}
        </span>

        <span
          className={fullClassName}
          style={{
            position: "absolute",
            top: 0,
            [rtl ? "right" : "left"]: 0,
            color:
              (allowHover &&
                hoverValue &&
                fillColorArray[valueIndex(hoverValue)]) ||
              (defaultValue && fillColorArray[valueIndex(defaultValue)]) ||
              fillColor,
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "inline-block",
            transition: transition ? "width .2s ease, color .2s ease" : "",
            width: `${valuePercentage}%`,
            ...fullStyle,
          }}
        >
          {[...Array(iconsCount)].map((_, index) => (
            <Fragment key={index}>
              {customIcons[index]?.icon || fullIcon || (
                <StarIcon size={size} style={{ marginRight }} />
              )}
            </Fragment>
          ))}
        </span>
      </span>
    </span>
  )
}

Rating.propTypes = {}

Rating.defaultProps = {}

export default Rating
