import React from "react"

export default function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <defs>
        <clipPath id="contact">
          <rect width="17" height="20" fill="#fff" />
        </clipPath>
      </defs>

      <g clipPath="url(#contact)">
        <path
          d="M16.862,16.071a6.619,6.619,0,0,0-2.23-3.1,2.651,2.651,0,0,0-1.241-.579,2.245,2.245,0,0,0-.5-.021,4.6,4.6,0,0,0-1.714.5,1.037,1.037,0,0,1-.636.084,1.457,1.457,0,0,1-.768-.374A18.93,18.93,0,0,1,6.42,8.091a1.22,1.22,0,0,1,.159-1.376A4.306,4.306,0,0,0,7.611,5.3,2.24,2.24,0,0,0,7.575,3.52,6.648,6.648,0,0,0,5.237.5a2.557,2.557,0,0,0-1.1-.468A2.465,2.465,0,0,0,2.261.534,18.353,18.353,0,0,0,.673,1.889,2.485,2.485,0,0,0,.018,3.576l0,.012a7.116,7.116,0,0,0,.407,2.7,19.036,19.036,0,0,0,2.924,5.589c.286.392,1.429,1.924,1.724,2.309A19.656,19.656,0,0,0,9.64,18.634a7.747,7.747,0,0,0,2.524,1.229l.012,0c.15.031.311.065.468.089a2.253,2.253,0,0,0,1.4-.152,18.686,18.686,0,0,0,1.836-1.022,2.231,2.231,0,0,0,.984-2.711ZM4.172,3.678c-.741-.993-.958-2.064-.485-2.392s1.457.21,2.2,1.2.958,2.064.485,2.392-1.457-.21-2.2-1.2M15.688,17.451c-.473.328-1.457-.21-2.2-1.2s-.958-2.064-.485-2.392,1.457.21,2.2,1.2.958,2.064.485,2.392"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
