import React, { useCallback, useEffect } from "react"

export const ListenEsc = ({ closeModal }) => {
  const escFunction = useCallback(event => {
    if (event.key === "Escape") {
      closeModal()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])
  return null
}

export default ListenEsc
