// Libraries.
import { Container } from "@shared/Container"
import { dark, white, bold } from "@shared/StyleConstants"
import styled from "styled-components"

export const Section = styled.section`
  padding: 150px 0;
`

export const H2 = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 444px;
  opacity: ${props => (props.submitted ? 0 : 1)};
  transition: opacity 0.5s ease;
  background-color: white;
  z-index: ${props => (props.submitted ? 0 : 1)};
`
export const SuccessForm = styled(Form)`
  text-align: center;
  opacity: ${props => (props.submitted ? 0 : 1)};
  z-index: ${props => (props.submitted ? -1 : 1)};
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
`

export const Button = styled.button`
  background-color: ${dark};
  border: 0;
  color: ${white};
  font-weight: ${bold};
  padding: 10px 29px;
  margin-top: 30px;
  max-width: 100px;
`

export const Label = styled.label`
  display: block;
  font-size: text;
  font-weight: 700;
  margin-bottom: 10px;
`

export const Input = styled.input`
  border: 1px solid ${dark};
  border-radius: 0;
  height: 40px;
  margin-bottom: 30px;
`
export const ReloadSection = styled.div`
  cursor: pointer;
  display: flex;
  text-align: center;
  align-self: center;
  justify-content: center;
`

export const ReloadSectionImage = styled.img`
  padding-right: 5px;
`

export const ReloadSectionText = styled.p`
  text-decoration: underline;
  font-weight: 700;
`

export const SuccessFormText = styled.div`
  line-height: 30px;
  margin-top: 140px;
`

export const ContactContainer = styled(Container)`
  height: 513px;
`
