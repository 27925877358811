import React from "react"

export default function PortfolioIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="portfolio">
          <rect width="20" height="16" fill="#fff" />
        </clipPath>
      </defs>
      <g transform="translate(-20 -148)">
        <g transform="translate(20 151)">
          <g clipPath="url(#portfolio)">
            <path
              d="M18.75,2.6H13.43V1.215A1.234,1.234,0,0,0,12.18,0H7.818a1.234,1.234,0,0,0-1.25,1.215V2.6H1.25A1.234,1.234,0,0,0,0,3.819V6.3L3.062,9.182H4.583v-.4a.824.824,0,0,1,.833-.81h1.25a.822.822,0,0,1,.833.81v.4h5v-.4a.824.824,0,0,1,.833-.81h1.25a.822.822,0,0,1,.833.81v.4h1.519L20,6.3V3.819A1.237,1.237,0,0,0,18.75,2.6ZM7.4,1.215A.411.411,0,0,1,7.818.81H12.18a.411.411,0,0,1,.417.405V2.6H7.4Z"
              fill="#fff"
            />
            <path
              d="M17.145,199.9H15.419v.405a.822.822,0,0,1-.833.81h-1.25a.824.824,0,0,1-.833-.81V199.9h-5v.405a.822.822,0,0,1-.833.81H5.419a.824.824,0,0,1-.833-.81V199.9H2.858a.421.421,0,0,1-.332-.16L0,197.326v7.362a1.231,1.231,0,0,0,1.25,1.215h17.5A1.234,1.234,0,0,0,20,204.688v-7.361l-2.525,2.408a.422.422,0,0,1-.332.16Z"
              transform="translate(-0.003 -189.903)"
              fill="#fff"
            />
          </g>
        </g>
        <rect
          width="20"
          height="20"
          transform="translate(20 148)"
          fill="none"
        />
      </g>
    </svg>
  )
}
