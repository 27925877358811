// Libraries.
import React from "react"

// Shared Components.
import { Container, Flex0, Flex1 } from "@shared/Container"
import Skills from "../Skills/Skills"

// Styles.
import { BottomHeader, GoalsContainer, Section } from "./styles"
import { Highligth } from "./styles"
import { H2 } from "./styles"

const Goals = () => {
  return (
    <Section>
      <Flex1>
        <Container>
          <GoalsContainer>
            <H2>My ultimate goal:</H2>
            <div>
              <Highligth>
                I’m looking for an opportunity to join a team with international
                exposure, whether in Mexico or abroad, for a company where high
                design and{" "}
                <span style={{ whiteSpace: "nowrap" }}>work-culture </span>
                standards allow me to take my work and professional experience
                to the next level.{" "}
              </Highligth>
              <span>
                As an entrepreneur, I’ve focused on managing businesses and
                interacting with clients. At this stage of my career, I’m ready
                and eager to be a team player, contributing and focusing my
                passion on maximizing my creative and artistic talents for a
                larger audience.
              </span>
            </div>
          </GoalsContainer>
          <BottomHeader>What I’m good at:</BottomHeader>
        </Container>
      </Flex1>
      <Flex0>
        <Skills />
      </Flex0>
    </Section>
  )
}

export default Goals
