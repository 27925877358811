import React from "react"

export default function Times() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
    >
      <g
        id="Group_51322"
        data-name="Group 51322"
        transform="translate(-60 -60)"
      >
        <rect
          id="Rectangle_22900"
          data-name="Rectangle 22900"
          width="24"
          height="17"
          transform="translate(60 60)"
          fill="none"
        />
        <path
          id="Union_1"
          data-name="Union 1"
          d="M23694.039,22835.205l-6.424-5.99-6.422,5.99a1.53,1.53,0,1,1-2.088-2.236l6.268-5.844-6.268-5.844a1.535,1.535,0,0,1,2.094-2.246l6.426,5.99,6.426-5.99a1.527,1.527,0,0,1,2.082,2.234l-6.268,5.846,6.273,5.848a1.536,1.536,0,0,1-2.1,2.242Z"
          transform="translate(-23615.617 -22758.621)"
          fill="#000"
        />
      </g>
    </svg>
  )
}
