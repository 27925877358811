// Libraries.
import React from "react"

// Shared Components.
import Rating from "@shared/Rating"
import {
  dark,
  white,
  yellow,
  opacity20,
  opacity40,
} from "@shared/StyleConstants"

// Components.
import ChevronIcon from "./assets/chevron.svg"

// Styles.
import {
  Card,
  CardAccordion,
  CardBody,
  CardBodyDivLeft,
  CardBodyDivRight,
  CardContainer,
  CardDescriptionSpan,
  CardHeader,
  CardHeaderStars,
  CardHeaderTitle,
  ChevronContainer,
  ContainerGrid,
  MainContainer,
} from "./styles"
import { useState } from "react"

const skillsData = [
  {
    title: "Branding",
    description:
      "I design all kinds of content (stationery, printed, digital, experiential...) to position brands and communicate their unique selling propositions, telling authentic stories that intend to inspire and connect with their audiences.",
    stars: 4,
    abilities: [
      { title: "Brand Definition", stars: 4.5 },
      { title: "Storytelling", stars: 5 },
      { title: "Graphic Identity", stars: 5 },
      { title: "Positioning", stars: 4 },
    ],
  },
  {
    title: "Digital Design",
    description:
      "I think up and design complex or straightforward applications and websites. Once I understand the business process to be automated or the problem to be solved, I can turn in a final prototype and all assets required to start the programming phase.",
    stars: 5,
    abilities: [
      { title: "Business Analysis", stars: 5 },
      { title: "UX Design", stars: 5 },
      { title: "UI Design", stars: 5 },
      { title: "Product Mgmt.", stars: 4.5 },
    ],
  },
  {
    title: "Marketing & Sales",
    description:
      "I attract clients by defining a strategy and creating engaging content that helps increase the conversion rate. I produce stunning material to pitch an idea, product, or service that astonishes clients.",
    stars: 4,
    abilities: [
      { title: "Advertising", stars: 4 },
      { title: "Inbound Strategy", stars: 4 },
      { title: "Content Creation", stars: 4 },
      { title: "Sales Pitch", stars: 5 },
    ],
  },
  {
    title: "Strategy",
    description:
      "I can analyze any problem and come up with a diagnosis, guiding policy, and coherent actions to solve it embodied in aesthetic and easy - to - understand presentations for everyone involved.",
    stars: 4,
    abilities: [
      { title: "Analysis", stars: 3.5 },
      { title: "Planning", stars: 4.5 },
      { title: "Communication", stars: 5 },
      { title: "Implementation", stars: 4.5 },
    ],
  },
]

const SkillCard = ({
  title,
  abilities,
  stars,
  description,
  setOpenedTab,
  tabOpened,
  index,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const handleToggleOpen = () => {
    const newTabs = [...tabOpened]
    if (isOpen) setOpenedTab(newTabs.filter(t => t !== index))
    else setOpenedTab([...tabOpened, index])
    setIsOpen(!isOpen)
  }
  return (
    <CardContainer>
      <Card isOpen={isOpen}>
        <CardHeader>
          <CardHeaderTitle>{title}</CardHeaderTitle>
          <CardHeaderStars>
            <Rating
              initialValue={stars}
              marginRight={8}
              readonly={true}
              size={24}
              fillColor={yellow}
              emptyColor={yellow + opacity40}
            />
          </CardHeaderStars>
        </CardHeader>
        {abilities.map((ability, index) => (
          <CardBody key={index}>
            <CardBodyDivLeft>
              <span>{ability.title}</span>
            </CardBodyDivLeft>
            <CardBodyDivRight>
              <Rating
                initialValue={ability.stars}
                marginRight={8}
                readonly={true}
                size={15}
                fillColor={isOpen ? dark : white}
                emptyColor={isOpen ? dark + opacity20 : white + opacity20}
              />
            </CardBodyDivRight>
          </CardBody>
        ))}
        <ChevronContainer
          isOpen={isOpen}
          src={ChevronIcon}
          onClick={handleToggleOpen}
        />
        <CardAccordion isOpen={isOpen}>
          <CardDescriptionSpan isOpen={isOpen}>
            {description}
          </CardDescriptionSpan>
        </CardAccordion>
      </Card>
    </CardContainer>
  )
}

const Skills = () => {
  const [tabOpened, setOpenedTab] = useState([])
  return (
    <MainContainer tabOpened={tabOpened}>
      <ContainerGrid>
        {skillsData.map((skill, index) => (
          <SkillCard
            key={index}
            index={index}
            tabOpened={tabOpened}
            setOpenedTab={setOpenedTab}
            {...skill}
          />
        ))}
      </ContainerGrid>
    </MainContainer>
  )
}

export default Skills
