// Libraries.
import styled from "styled-components"

// Assets.
import bgHero from "./assets/bg-hero.jpg"

export const Hamburger = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30px;
  @media (min-width: 1280px) {
    top: 60px;
    left: -90px;
  }
  @media (min-width: 1792px) {
    top: 60px;
    left: -276px;
  }
`

export const SectionHero = styled.header`
  height: calc(
    ${props => (props.height ? `${props.height}px` : "100vh")} - 8px
  );
  background: url(${bgHero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 8px;
`
export const WrapperHero = styled.div`
  height: 100%;
  display: flex;
  h1 {
    margin-bottom: 20px;
    line-height: 58px;
  }
  p {
    line-height: 30px;
  }
  @media (min-width: 360px) {
    padding-top: 77px;
  }
  @media (min-width: 375px) {
    padding-top: 117px;
  }
  @media (min-width: 600px) {
    align-items: center;
    padding: 0;
    h1 {
      line-height: 50px;
    }
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1792px) {
  }
`

export const HeroImgMobile = styled.img`
  position: absolute;
  z-index: 1000;
  bottom: -8px;
  right: 0px;
  width: 180px;
  @media (min-width: 420px) {
    display: none;
  }
`

export const HeroImg = styled.img`
  position: absolute;
  bottom: -8px;
  right: 0;

  @media (min-width: 900px) {
    right: -100px;
  }
  @media (min-width: 1120px) {
    right: 0;
  }
  @media (min-width: 1720px) {
    right: -188px;
  }
`
