// Libraries.
import React from "react"

// Components.
import Times from "./assets/times"
import Linkedin from "./assets/linkedin"
import ContactIcon from "./assets/contact"
import DownloadIcon from "./assets/download"

// Styles.
import {
  SidebarContainer,
  SidebarMain,
  CloseButton,
  SidebarBody,
  Title,
  ListItems,
  ResumeLink,
  ListLink,
  Icon,
} from "./styles"
import { Blured } from "@shared/Blured"
import { Flex1 } from "@shared/Container"
import PortfolioIcon from "./assets/portfolio"
import { Flex0 } from "@shared/Container"

import pdf from "@shared/prueba.pdf"

function SideBar({ isOpen, closeModal }) {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Blured isOpen={isOpen} />
      <SidebarMain isOpen={isOpen}>
        <SidebarBody>
          <Flex1>
            <CloseButton onClick={closeModal}>
              <Times></Times>
            </CloseButton>
            <Title>Diego Trejo</Title>
            <div>
              <ListItems>
                <ResumeLink
                  href={
                    "https://www.dropbox.com/sh/ik6ket1xzaoicn4/AAD0BZsJE6q42mgxnecYAIa8a?dl=0&preview=RESUME-DT.pdf"
                  }
                  target="_blank"
                  onClick={closeModal}
                >
                  <Icon>
                    <DownloadIcon />
                  </Icon>
                  Resume
                </ResumeLink>
              </ListItems>
              <ListItems>
                <ListLink href="#section_portfolio" onClick={closeModal}>
                  <Icon>
                    <PortfolioIcon />
                  </Icon>
                  My portfolio
                </ListLink>
              </ListItems>
              <ListItems>
                <ListLink href="#section_contact" onClick={closeModal}>
                  <Icon>
                    <ContactIcon />
                  </Icon>
                  Contact
                </ListLink>
              </ListItems>
            </div>
          </Flex1>
          <Flex0>
            <a
              href="https://www.linkedin.com/in/diatredu/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Linkedin />
            </a>
          </Flex0>
        </SidebarBody>
      </SidebarMain>
    </SidebarContainer>
  )
}

export default SideBar
