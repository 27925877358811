import styled, { createGlobalStyle } from "styled-components"
import { blueish, opacity60 } from "@shared/StyleConstants"
import Eye from "@sections/Briefcase/assets/eye.png"

export const Container = styled.div`
  position: relative;
  padding: 0 20px;
  margin: 0;
  width: 100%;

  @media (min-width: 160px) {
    margin: 0 auto;
    max-width: 244px;
  }
  @media (min-width: 360px) {
    margin: 0 auto;
    max-width: 348px;
  }
  @media (min-width: 558px) {
    margin: 0 auto;
    max-width: 556px;
  }
  @media (min-width: 768px) {
    margin: 0 auto;
    padding: 0 18px;
    max-width: 760px;
  }
  @media (min-width: 1120px) {
    max-width: 1072px;
  }
  @media (min-width: 1280px) {
    max-width: 1120px;
  }
  @media (min-width: 1792px) {
    max-width: 1120px;
  }
`

export const ModalWrapper = styled.div`
  z-index: ${props => (props.isOpen ? 2 : -1)};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  display: ${props => (props.isOpen ? "visible" : "none")};
  overflow-y: auto;
`
export const ModalContainer = styled.div`
  position: fixed;
  z-index: ${props => (props.isOpen ? 2 : -1)};
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  transition: all 0.3s;
  @media (max-height: 800px) {
    display: block;
  }
  @media (max-width: 1280px) {
    display: block;
  }
`
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
`
export const CursorPointerDiv = styled.div`
  cursor: pointer;
`
export const ModalImageStyled = styled.div`
  width: 100px;
  height: 100px;
`
export const SubContainer = styled.div`
  overflow: auto;
  @media (max-width: 1280px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`
export const ModalBody = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`
export const ModalMainImage = styled.div`
  position: relative;
  width: calc(100% - 312px);
  margin-right: 4px;
  @media (min-width: 175px) {
    margin-bottom: 4px;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`
export const WrapperVideo = styled.div`
  height: 100%;
  background: black;
`

export const Arrows = styled.div`
  div:first-child {
    cursor: pointer;
    color: red;
    position: absolute;
    top: calc(50% - 15px);
    z-index: 100;

    right: 20px;
  }
  div:last-child {
    cursor: pointer;
    color: green;
    position: absolute;
    top: calc(50% - 15px);

    z-index: 100;
    left: 20px;
  }
`

export const ModalRightImages = styled.div`
  width: 312px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  height: 100%;
  @media (max-width: 1280px) {
    width: 100%;
  }
`
export const LeftImage = styled.img`
  width: 100%;
  height: 516px;
`
export const LeftVideo = styled.video`
  width: 100%;
  height: 516px;
`

export const ModalImageSelector = styled.div`
  width: 100px;
  height: 100px;
  border: 2px solid black;
  position: absolute;
  background-color: ${blueish + opacity60};
  backdrop-filter: blur(1px);
  z-index: 2;
  background-image: url(${Eye});
  background-size: 40px 26px;
  background-position: center;
  transition: all 0.3s;
  background-repeat: no-repeat;
`
export const Text = styled.span`
  line-height: 30px;
`
export const GlobalStyle = createGlobalStyle`
  body{
    overflow:none ;
  }
`
