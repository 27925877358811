import styled from "styled-components"

import { blueish, opacity86 } from "@shared/StyleConstants"

export const Blured = styled.div`
  height: 100%;
  width: 100%;
  z-index: ${props => (props.isOpen ? 2 : -1)};
  position: fixed;
  background-color: ${blueish + opacity86};
  backdrop-filter: blur(3px);
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all 0.3s;
`
