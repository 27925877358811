// Libraries.
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"

// Shared Components.
import { Container } from "@shared/Container"

// Data.
import { BriefcaseData } from "./BriefcaseData"

// Assets.
import filterIcon from "./assets/filter.svg"
import arrowSelectIcon from "./assets/arrow-select.svg"
import arrowHover from "./assets/arrow-hover.svg"
import nomi from "./project-images/02 DIGITAL DESIGN/01_DIGITAL_NOMI-WEBSITE/02_DIGITAL_NOMI-website.jpg"

// Styles.
import {
  WrapperBriefcase,
  WrapperFilter,
  WrapperSelect,
  TitleBriefcase,
  Pointer,
  Image,
  Dropdown,
} from "./styles"
import { element } from "prop-types"

const GalleryImage = ({
  id,
  setData,
  mainImage,
  year,
  client,
  area,
  project,
  modal,
  selected,
}) => {
  return (
    <Pointer
      id={`card-${id}`}
      onClick={() => setData({ modal })}
      hide={selected}
      className={!selected && "remove-card"}
    >
      <div className="overlay">
        <div>
          <div>
            Year: <span>{year}</span>
          </div>
          <div>
            Area:
            <span>{area}</span>
          </div>
          <div>
            Client: <span>{client}</span>
          </div>
          <div>
            Project: <span>{project}</span>
          </div>
        </div>
        <img src={arrowHover} width={64} height={62} alt="arrow" />
      </div>
      {mainImage}
    </Pointer>
  )
}

const Briefcase = props => {
  const [filter, setFilter] = useState(BriefcaseData)
  const [label, setLabel] = useState("Show all")
  const [open, setOpen] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) })

  useEffect(() => {
    const removeCard = () => {
      filter.forEach(element => {
        if (!element.selected) {
          const elementAux = document.getElementById(`card-${element.id}`)
          setTimeout(function () {
            elementAux.style.cssText = "display:none;"
          }, 500)
        } else {
          const elementAux = document.getElementById(`card-${element.id}`)
          setTimeout(function () {
            elementAux.style.cssText = "display:block;"
          }, 500)
        }
      })
    }
    removeCard()
  }, [filter])
  const updateFilter = project => {
    if (project.id === "all") {
      setFilter(BriefcaseData)
      setLabel(project.label)
    } else {
      const updateBriefcaseData = BriefcaseData.map(item => {
        if (item.projectType !== project.id) {
          return { ...item, selected: false }
        } else {
          return item
        }
      })

      setFilter(updateBriefcaseData)
      setLabel(project.label)
    }
  }

  return (
    <section id="section_portfolio">
      <Container>
        <TitleBriefcase>
          <h2>My work:</h2>
        </TitleBriefcase>
      </Container>
      <hr />
      <Container>
        <WrapperFilter>
          <img src={filterIcon} alt="filter icon" />
          <WrapperSelect
            onClick={() => {
              setOpen(!open)
            }}
            ref={ref}
            open={open}
          >
            <Dropdown>
              {label}
              <img
                src={arrowSelectIcon}
                alt="arrow icon"
                className={`arrow-icon ${open ? "rotate-arrow" : ""}`}
              />
            </Dropdown>

            <ul className="sub-menu">
              <li
                onClick={() => updateFilter({ id: "all", label: "Show all" })}
              >
                Show all
              </li>
              <li
                onClick={() =>
                  updateFilter({ id: "branding", label: "Branding" })
                }
              >
                Branding
              </li>
              <li
                onClick={() =>
                  updateFilter({
                    id: "digitalDesign",
                    label: "Digital Design",
                  })
                }
              >
                Digital Design
              </li>
              <li
                onClick={() =>
                  updateFilter({
                    id: "marketingSales",
                    label: "Marketing & Sales",
                  })
                }
              >
                Marketing & Sales
              </li>
              <li
                onClick={() =>
                  updateFilter({ id: "strategy", label: "Strategy" })
                }
              >
                Strategy
              </li>
            </ul>
          </WrapperSelect>
        </WrapperFilter>
      </Container>
      <WrapperBriefcase>
        {filter.map(project => {
          return (
            <GalleryImage
              key={project.id}
              id={project.id}
              mainImage={project.mainImage}
              year={project.year}
              area={project.area}
              client={project.client}
              project={project.project}
              modal={project.modal}
              selected={project.selected}
              {...props}
            />
          )
        })}
      </WrapperBriefcase>
    </section>
  )
}

export default Briefcase
