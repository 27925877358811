// Libraries.
import styled from "styled-components"

import {
  dark,
  white,
  title4,
  title5,
  yellow,
  bold,
  regular,
} from "../../shared/StyleConstants"

export const Section = styled.section`
  background-color: ${dark};
  color: ${white};
  padding: 25px 0;
`

export const H3 = styled.h3`
  font-size: ${title4};
  font-weight: ${regular};
  line-height: 44px;
`

export const H2 = styled.h2`
  font-size: ${title5};
  font-weight: ${bold};
  margin-bottom: 14px;
  line-height: 58px;
`

export const A = styled.a`
  align-items: center;
  color: ${yellow};
  display: flex;
  font-weight: ${bold};
  text-decoration: underline;
  line-height: 26px;
`

export const Img = styled.img`
  margin-right: 10px;
`
