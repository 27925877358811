// Libraries.
import styled from "styled-components"

export const WrapperCarousel = styled.div`
  .carousel-root {
    width: 100%;
  }
  .carousel.carousel-slider {
    padding-bottom: 50px;
  }
  .carousel .control-dots {
    bottom: -12px;
    z-index: 10;
  }
  .carousel .control-dots .dot {
    border: 1px solid #000000;
    opacity: 1;
    margin: 0px 5px;
    width: 14px;
    height: 14px;
    box-shadow: none;
  }

  .carousel .control-dots .dot.selected {
    background: #000000;
  }
`

export const WrapperClientSection = styled.div`
  margin: 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .comillas-open {
    position: absolute;
    top: -38px;
    left: 0;
    width: 75px !important;
  }
  .comillas-close {
    position: absolute;
    right: 0;
    top: 202px;
    width: 75px !important;
  }
  p {
    line-height: 30px;
    text-align: center;
  }

  @media (min-width: 768px) {
    .comillas-open {
      top: -36px;
    }
    .comillas-close {
      top: 75px;
    }
  }
  @media (min-width: 1280px) {
    .comillas-open {
      left: 180px;
    }
    .comillas-close {
      right: 180px;
    }
  }
`

export const WrapperDescription = styled.p`
  @media (min-width: 1280px) {
    ${props => (props.width ? `width: ${props.width}` : "")};
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
`

export const DescriptionClient = styled.div`
  margin-bottom: 60px;
`
export const TextWhite = styled.p`
  ${props => (props.color ? "" : "color: white;")}
`
export const DataClient = styled.div`
  margin-bottom: 30px;
`
export const WrapperLogo = styled.img`
  width: 178px !important;
  height: 60px;
`
