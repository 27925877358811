// Libraries.
import React from "react"

// Shared Components.
import { Container } from "../../shared/Container"

// Styles.
import { Section } from "./styles"
import { H2 } from "./styles"
import { H3 } from "./styles"
import { A } from "./styles"
import { Img } from "./styles"

// Assets.
import download from "./assets/download-icon.svg"
import pdf from "../../shared/prueba.pdf"

const Name = () => {
  return (
    <Section>
      <Container>
        <H3>My name is</H3>
        <H2>Diego Trejo</H2>
        <A
          href={
            "https://www.dropbox.com/sh/ik6ket1xzaoicn4/AAD0BZsJE6q42mgxnecYAIa8a?dl=0&preview=RESUME-DT.pdf"
          }
          target="_blank"
        >
          <Img src={download} alt="Download icon" />
          Resume
        </A>
      </Container>
    </Section>
  )
}

export default Name
