// Libraries.
import styled from "styled-components"

// Styles.
import { dark, white, yellow } from "@shared/StyleConstants"

export const SidebarContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
  z-index: ${props => (props.isOpen ? 2 : -1)};
`
export const SidebarMain = styled.div`
  overflow-y: auto;
  color: ${white};
  background-color: ${dark};
  height: 100%;
  width: 350px;
  position: fixed;
  z-index: 2;
  display: flex;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all 0.5s;
  left: ${props => (props.isOpen ? "0" : "-350px")};
  @media (max-width: 767px) {
    width: 250px;
  }
`
export const CloseButton = styled.div`
  cursor: pointer;
  margin-bottom: 60px;
  align-items: center;
  flex-direction: column;
  @media (max-height: 400px) {
    margin-bottom: 30px;
  }
`
export const SidebarBody = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: space-between;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (min-width: 768px) {
    padding: 60px;
  }
  @media (min-width: 1280px) {
    padding: 60px;
  }
  @media (min-width: 1792px) {
    padding: 60px;
  }
`
export const Title = styled.h4`
  margin-bottom: 60px;
  @media (max-height: 400px) {
    margin-bottom: 20px;
  }
`
export const ListItems = styled.div`
  margin-bottom: 40px;
  @media (max-height: 400px) {
    margin-bottom: 20px;
  }
`
export const ResumeLink = styled.a`
  text-decoration: none;
  color: ${yellow};
  display: flex;
  align-items: center;
`
export const ListLink = styled.a`
  text-decoration: none;
  color: ${white};
  display: flex;
  align-items: center;
`
export const Icon = styled.div`
  margin-right: 20px;
`
