// Libraries.
import styled from "styled-components"

import { bold } from "../../shared/StyleConstants"

// Assets.
import map from "./assets/map.jpg"
import mapPhone from "./assets/map-phone.jpeg"
import mapTablet from "./assets/map-tablet.jpeg"

export const Section = styled.section`
  background-image: url(${map});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  min-height: 1318px;
  @media (max-width: 760px) { 
    background-image: url(${mapTablet});
  }
  @media (max-width: 576px) { 
    background-image: url(${mapPhone});
  }
`

export const H2 = styled.h2`
  margin-bottom: 20px;
`

export const BottomHeader = styled.h2`
  margin-bottom: 60px;
`

export const Highligth = styled.span`
  font-weight: ${bold};
`
export const GoalsContainer = styled.div`
  padding-top: 365px;
  padding-bottom: 365px;
  line-height: 30px;
`
