// Libraries.
import React, { useState } from "react"

// Styles.
import {
  Button,
  Form,
  Input,
  Label,
  H2,
  Section,
  SuccessForm,
  ReloadSection,
  SuccessFormText,
  ReloadSectionImage,
  ReloadSectionText,
  ContactContainer,
} from "./styles"

// Shared Components.
import Reload from "./assets/reload.svg"

const defaultState = { name: "", email: "", message: "" }

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")

const Contact = () => {
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formState, setFormState] = useState({ ...defaultState })

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => {
        setFormState({ ...defaultState })
        setSubmitted(true)
      })
      .catch(error => {
        console.log(error)
        setSubmitted(true)
        setFormState({ ...defaultState })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Section id="section_contact">
      <ContactContainer>
        <H2>Contact</H2>
        <Form
          onSubmit={handleSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          submitted={submitted}
        >
          <input type="hidden" name="form-name" value="contact" />

          <Label>Name:</Label>
          <Input
            id="name"
            name="name"
            type="text"
            onChange={handleChange}
            value={formState.name}
          />

          <Label>E-mail:</Label>
          <Input
            id="email"
            name="email"
            type="text"
            onChange={handleChange}
            value={formState.email}
          />

          <Label>Message:</Label>
          <textarea
            id="message"
            name="message"
            type="text"
            onChange={handleChange}
            value={formState.message}
            rows="8"
          />

          <Button type="submit" disabled={submitting || submitted}>
            Send
          </Button>
        </Form>
        <SuccessForm submitted={!submitted}>
          <SuccessFormText>
            <p>Thank's a lot for getting in touch.</p>
            <p>I'll answer back as soon as possible.</p>
            <br />
            <p>Greetings,</p>
            <p>
              <b>Diego Trejo</b>
            </p>
            <br />
            <br />
            <ReloadSection onClick={() => setSubmitted(false)}>
              <ReloadSectionImage src={Reload} />
              <ReloadSectionText>Reload Form</ReloadSectionText>
            </ReloadSection>
          </SuccessFormText>
        </SuccessForm>
      </ContactContainer>
    </Section>
  )
}

export default Contact
