import React from "react"

export default function DownloadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="download">
          <rect width="20" height="20" fill="#bafe00" />
        </clipPath>
      </defs>
      <g clipPath="url(#download)">
        <path
          d="M20,302.786v4.435a1.016,1.016,0,0,1-.031.246.951.951,0,0,1-.926.763H.952a.948.948,0,0,1-.938-.846.746.746,0,0,1-.013-.17v-4.435a.944.944,0,0,1,1.612-.669.951.951,0,0,1,.277.669v3.552H18.118v-3.552a.944.944,0,0,1,1.612-.669,1,1,0,0,1,.271.675Z"
          transform="translate(-0.001 -288.231)"
          fill="#bafe00"
        />
        <path
          d="M105.431,10.113l-4.641,4.65c-.006.013-.019.019-.025.025a1.092,1.092,0,0,1-.611.309c-.019,0-.038.006-.057.006a.683.683,0,0,1-.113.006l-.107-.006c-.019,0-.038-.006-.057-.006a1.072,1.072,0,0,1-.611-.309l-.025-.025-4.641-4.65A1.105,1.105,0,0,1,96.1,8.549l2.79,2.795V1.1A1.108,1.108,0,0,1,100,0a1.125,1.125,0,0,1,.781.322,1.092,1.092,0,0,1,.321.782v10.24l2.79-2.795a1.108,1.108,0,0,1,1.562,0,1.131,1.131,0,0,1-.019,1.565Z"
          transform="translate(-89.984)"
          fill="#bafe00"
        />
      </g>
    </svg>
  )
}
