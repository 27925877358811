// Libraries.
import { white } from "@shared/StyleConstants"
import styled from "styled-components"

export const TitleBriefcase = styled.div`
  margin-bottom: 20px;
`
export const WrapperBriefcase = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, [col] 1fr);
  grid-gap: 8px;
  padding: 20px 8px 30px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, [col] 1fr);
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, [col] 1fr);
  }
`
export const WrapperFilter = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;

  .arrow-icon {
    position: absolute;
    right: 16px;
    top: calc(50% - 4px);
    transition: 0.5s;
    transform: rotate(0deg);
    pointer-events: none;
  }

  &:hover .rotate-arrow {
    transition: 0.5s;
    transform: rotate(180deg);
  }
`
export const Dropdown = styled.div`
  position: relative;
  margin-bottom: 4px;

  border: 1px solid #000000;
  background-color: ${white};
  padding: 10px;
  font-size: 17px;
  width: 200px;
`

export const WrapperSelect = styled.div`
  cursor: pointer;
  position: relative;
  /* &:focus .sub-menu,
  &:focus-within .sub-menu,
  &:hover */
  ${props =>
    props.open
      ? ` &:focus .sub-menu,
  &:focus-within .sub-menu,
  &:hover`
      : ""}
  .sub-menu {
    visibility: visible; /* shows sub-menu */
    opacity: 1;
    z-index: 2;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  ul {
    background: white;
    border: 1px solid #000000;
    padding: 0;
    li {
      cursor: pointer;
      padding: 10px;
      &:hover {
        background: #b4cad2;
      }
    }
  }

  .sub-menu {
    visibility: hidden; /* hides sub-menu */
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(-2em);
    z-index: 2;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
  }
`

export const Image = styled.img`
  object-fit: cover;
  &:hover {
  }
`
export const Pointer = styled.div`
  position: relative;
  ${props =>
    !props.hide
      ? "animation: hideMe 1s forwards;"
      : "animation: showMe 1s forwards;"}
  @keyframes hideMe {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes showMe {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    font-size: 17px;
    line-height: 30px;
    span {
      font-weight: bold;
    }
    padding: 30px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    z-index: 1;
    opacity: 0;
    &:hover {
      opacity: 0.85;
      background: #b4cad2 0% 0% no-repeat padding-box;
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
    }

    img {
      width: 32px;
      height: 31px;
    }
  }

  @media (min-width: 1280px) {
    .overlay {
      img {
        width: 62px;
        height: 61px;
      }
    }
  }
  @media (min-width: 1792px) {
    .overlay {
      padding: 60px;
    }
  }
`
