// Libraries.
import styled from "styled-components"

import { blueish, dark, white } from "../../shared/StyleConstants"

export const MainContainer = styled.div`
  /* margin-top:-10px; */
  @media (min-width: 200px) {
    margin-bottom: ${props => (props.tabOpened.includes(3) ? "0px" : "300px")};
  }
  @media (min-width: 768px) {
    margin-bottom: ${props =>
      props.tabOpened.includes(3) || props.tabOpened.includes(2)
        ? "0px"
        : "300px"};
  }
  @media (min-width: 1280px) {
    margin-bottom: ${props => (props.tabOpened.length ? "0px" : "300px")};
  }
`

export const ContainerGrid = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(4, [col] 1fr); */
  /* grid-column-gap: 8px; */
  display: flex;
  flex-wrap: wrap;
  /* background: white; */
  /* overflow: auto; */
  padding-bottom: 40px;
  padding-right: 4px;
  padding-left: 4px;
`
export const CardContainer = styled.div`
  padding: 4px;
  height: 100%;
  flex: auto;
  transition-property: opacity, left, top, height, visibility, background-color;
  transition-duration: 3s, 5s, 3s, 5s, 3s, 5s;

  @media (max-width: 375px) {
    width: 100%;
  }
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
  @media (min-width: 1280px) {
    width: 25%;
  }
`
export const Card = styled.div`
  background-color: ${props => (props.isOpen ? "inherited" : dark)};
  background-image: ${props =>
    props.isOpen && `linear-gradient(${blueish}, ${white})`};
  transition-property: color, background-image, background-color;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: ease, ease, ease;
  color: ${props => (props.isOpen ? dark : white)};
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: 0px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (min-width: 1200px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (min-width: 1300px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (min-width: 1700px) {
    padding-right: 84px;
    padding-left: 84px;
  }
`
export const CardHeader = styled.div``

export const CardBody = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-content: space-between;
`
export const CardBodyDivRight = styled.div`
  text-align: end;
`
export const CardBodyDivLeft = styled.div`
  flex: 1;
  white-space: nowrap;
`
export const CardHeaderTitle = styled.h4`
  /* margin: 0; */
  font-weight: normal;
`
export const CardHeaderStars = styled.div`
  padding-top: 27px;
  padding-bottom: 40px;
`
export const CardAccordion = styled.div`
  line-height: 30px;
  margin-bottom: ${props => (props.isOpen ? "50px" : "0px")};
  height: ${props => (props.isOpen ? "250px" : "0px")};
`

export const CardDescriptionSpan = styled.span`
  position: relative;
  top: ${props => (props.isOpen ? "50px" : "-250px")};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  z-index: ${props => (props.isOpen ? 1 : -1)};
  transition-property: top, opacity;
  transition-duration: 0.5s, 0.5s;
  transition-timing-function: ease, ease;
`

export const ChevronContainer = styled.img`
  position: relative;
  transform: ${props => (props.isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
  transform-origin: center center;
  transform-box: fill-box;
  transition: 0.5s;
  z-index: 1;
  cursor: pointer;
  margin-top: 40px;
  filter: ${props => (props.isOpen ? "invert(0%)" : "invert(100%)")};
`
